<template>
  <div class="applyFlexCenter tw-flex-col tw-mt-12">

    <div class="tw-px-4">
<!--      <div class="py-3 tw-hidden lg:tw-block">-->
<!--        <button @click="$router.go(-1)" class="tw-flex">-->
<!--          <ChevronLeftIcon/>-->
<!--          <p class="tw-font-bold tw-text-base">Back</p>-->
<!--        </button>-->
<!--      </div>-->
      <h5 class="form">Recruit best fit <br class="lg:tw-hidden"> for your Business</h5>

      <validation-observer ref="observer" v-slot="{ handleSubmit }">

        <form @submit.prevent="handleSubmit(submit)">

          <div class="width lg:tw-flex tw-w-full">
            <div class="tw-w-full tw-mr-1 tw-mb-2 lg:tw-mb-0">
              <small>First Name</small>
              <validation-provider
                  v-slot="{ errors }"
                  name="First Name"
                  rules="required">
                <v-text-field
                    hide-details
                    label="First Name"
                    v-model="corporate.first_name"
                    solo
                    id="firstName"></v-text-field>
                <div class="tw-flex">
                  <!--                        <AlertCircleIcon v-show="errors" size=".8x" class="custom-class"/>-->
                  <span class="span tw-mb-2 lg:tw-mb-0" v-show="errors"> {{ errors[0] }}</span>
                </div>
              </validation-provider>
            </div>
            <div class="tw-w-full tw-ml-1">
              <small>Last Name</small>
              <validation-provider
                  v-slot="{ errors }"
                  name="Last Name"
                  rules="required">
                <v-text-field
                    hide-details
                    label="Last Name"
                    v-model="corporate.last_name"
                    id="lastName"
                    solo>
                </v-text-field>
                <span class="span" v-show="errors">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>

          <div class="width">
            <small>Work Email</small>
            <validation-provider
                v-slot="{ errors }"
                name="Email Address"
                rules="required|email">
              <v-text-field
                  hide-details
                  label="Email Address"
                  :disabled="autoEmail"
                  v-model="corporate.email"
                  solo
                  @input="value => { corporate.email = value.toLowerCase();}"
                  id="email">
              </v-text-field>
              <span class="span" v-show="errors">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="width">
            <small class="tw--mb-0.5">Phone</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Number"
                    rules="required">
                  <VuePhoneNumberInput
                      v-model="number"
                      :preferred-countries="['NG', 'GH', 'US']"
                      class="mb-4"
                      default-country-code="NG"
                      errorColor="red"
                      size="lg"
                      @input="typing=true"
                      @update="updatePhone"

                  />
                  <span class="span" v-show="errors">{{ errors[0] }}</span>
                </validation-provider>
          </div>

          <div class="width">
            <small>Create Password</small>
            <validation-provider
                v-slot="{ errors }"
                rules="required|min:8"
                name="Password">
              <v-text-field
                  hide-details
                  label="**********"
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  v-model="corporate.password"
                  solo
                  name="password"
                  id="password"></v-text-field>
              <span class="span" v-show="errors">{{ errors[0] }}</span>
            </validation-provider>
          </div>

          <div class="tw-flex tw-pt-6">
            <v-checkbox
                v-model="corporate.checkbox"
                color="#8F47F4"
                type="checkbox" class="mt-0 pt-0">
            </v-checkbox>
            <span @click="corporate.checkbox = !corporate.checkbox" style="cursor: pointer" class="tw-ml-2 terms">I agree to the<span
                style="color:#008EEF; cursor: pointer"> Terms & Conditions</span></span>
          </div>
         <BaseButton class="mb-4" type="submit" :loading="loading" height="54px" :block="true" id="register" button-text="Register"/>
        </form>
      </validation-observer>
    </div>
  </div>

</template>

<script>

import BaseButton from "@/components/UIComponents/BaseButton";
import {signUp, signUpNonExistingAdmin} from "@/services/api/AuthApiService";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  name: "BusinessSignUpForm",
  components: {BaseButton, VuePhoneNumberInput},
  data() {
    return {
      value: false,
      loading: false,
      showLoader: null,
      errorMessages: '',
      autoEmail: false,
      number: '',
      corporate: {
        checkbox: false,
        first_name: '',
        last_name: '',
        password: '',
        organisation: '',
        email: '',
        phone_number: '',
        corporate: true,
        application: 'recruitment'
      }
    }
  },

  created() {
    if (this.$route.query.email) {
      this.corporate.email = this.$route.query.email
      this.autoEmail = true
    }
  },
  methods: {
    checkTerm() {
      if (this.corporate.checkbox) {
        return true
      } else {
        this.$displaySnackbar({
          message: 'Please check the T&C',
          success: false
        })
        return false
      }
    },
    updatePhone({ formattedNumber }) {
      this.corporate.phone_number = formattedNumber;
    },
    async submit() {
      if (this.checkTerm()) {
        const data = this.corporate
        this.loading = true;
        if (this.$route.query.company_name && this.$route.query.role) {
          sessionStorage.setItem('adminDetails', JSON.stringify({
            siteName: this.$route.query.site_name }))
          let siteName = JSON.parse(sessionStorage.getItem('adminDetails'))
          console.log(siteName)
          await signUpNonExistingAdmin(data).then(async res => {
            console.log(res)
            await this.$store.dispatch("onBoarding/userLogin", {
              email: this.corporate.email,
              password: this.corporate.password
            }).then((res) => {
              console.log(res)
              this.$router.push({name: 'InviteAnAdminView', params: {step: '1'}})
            })
            this.$displaySnackbar({
              message: "Admin Signed-up Successfully",
              success: true
            })
          })
              .catch(err => {
                // console.log(err, err.response.data)
                let errorKey = Object.keys(err.response.data)
                this.$displaySnackbar({
                  message: err.response.data[errorKey[0]][0],
                  success: false
                })
              })
        } else {
        signUp(data).then(res => {
          console.log(res)
          this.$displaySnackbar({
            message: "Signed-up Successfully",
            success: true
          })
          this.$router.push({name: 'SignUpDone'})
        })
            .catch(err => {
              console.log(err,)
              // let errorKey = Object.keys(err.response.data)
              // this.$displaySnackbar({
              //   message: err.response.data[errorKey[0]][0],
              //   success: false
              // })
            })
            .finally(() => this.loading = false)
      }
    }
  }}
}
</script>

<style scoped>
.form {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  margin-bottom: 30px;
  margin-top: 20px;
  /*margin-right: 20px;*/
  color: #001343;
}

.width {
  margin-bottom: 25px;
}

.span {
  font-family: DM Sans serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #FF2E2E;
}

.terms {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.v-application p {
  margin-bottom: 0;
}

</style>
