<template>
  <Onboardlayout>
    <template #header>
      <Header>
        <template #right-header-component>
          <div class="tw-flex">
            <p class="tw-text-enumGrey3 tw-mt-3 already">Already have an account?</p>
            <BaseButton width="30px" :text="true" @click="loginTo" button-text="Login"></BaseButton>
          </div>
        </template>
      </Header>
    </template>

    <template #main-content>
      <div class="tw-h-full tw-overflow-hidden">
<!--        <component :is="signUpSwitch()"></component>-->
        <business-sign-up-form />
      </div>
    </template>
  </Onboardlayout>
</template>

<script>

import BusinessSignUpForm from "@/components/onboarding/components/registration/BusinessSignUpForm";
// import IndividualSignUpForm from "@/components/onboarding/components/registration/IndividualSignUpForm";
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";
import Header from "@/components/onboarding/reuseables/Header";
import BaseButton from "@/components/UIComponents/BaseButton";

export default {
  name: "RegistrationPage",
  components: {Onboardlayout, BusinessSignUpForm, Header, BaseButton},
  methods: {
    loginTo() {
      this.$router.push({name: 'Login'})
    },

    // signUpSwitch() {
    //   let type = this.$route.params.type
    //   if (type === 'business') {
    //     return BusinessSignUpForm
    //   } else {
    //     return IndividualSignUpForm
    //   }
    // }
  }
}
</script>

<style scoped>

</style>